<template>
  <div class="accoutOrderItems">
    <div v-for="(item, key) of this.orders" :key="key" class="orderItem">
      <q-expansion-item v-if="isMobile">
        <template v-slot:header>
          <q-item-section avatar>
            <SvgIcon
              color="primaryOnBody"
              size="1.5rem"
              :icon="item.codeName"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label
              caption
              v-html="item.displayName"
              style="font-size: 9px"
            />
            <q-item-label v-html="item.orderNumber" />
            <q-item-label caption style="font-size: 10px">{{
              item.orderDate | formatDate
            }}</q-item-label>
            <q-item-label caption>
              <div
                v-if="orderType == 'CO'"
                class="btn-cancel q-mr-sm"
                style="font-size: 10px"
              >
                <SvgIcon icon="close" />Canceled
              </div>
              <span
                v-if="orderType == 'OH' && item.hasAnyReturn"
                class="btn-returned q-mr-sm"
                style="font-size: 10px"
              >
                <SvgIcon icon="return" />Return
                <!-- Initiated -->
              </span>
              <span
                v-if="orderType == 'OH'"
                class="btn-completed q-mr-sm"
                style="font-size: 10px"
              >
                <SvgIcon icon="checkmark" />Completed
              </span>
            </q-item-label>
          </q-item-section>
        </template>

        <q-card>
          <q-card-section class="orderItemMiddle">
            <!-- -->
            <ul class="orderItemInfo">
              <li v-if="orderType == 'OH'">
                <span>Store:</span>
                {{ item.storeName }}
              </li>
              <li v-if="item.pickupStore">
                <span>Pickup From:</span>
                {{ item.pickupStore.name }}
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  content-class="tooltip-150"
                >
                  {{
                    item.pickupStore.line1 +
                    (item.pickupStore.line2
                      ? ', ' + item.pickupStore.line2
                      : '') +
                    ', ' +
                    item.pickupStore.city +
                    ', ' +
                    item.pickupStore.state
                  }}
                </q-tooltip>
              </li>
              <li v-if="item.shippingAddress">
                <span>{{
                  item.codeName == 'scd' ? 'Deliver To:' : 'Ship To:'
                }}</span>
                {{
                  (
                    (item.shippingAddress.firstName || '') +
                    ' ' +
                    (item.shippingAddress.lsatName || '')
                  ).trim()
                }}
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  content-class="tooltip-150"
                >
                  {{
                    item.shippingAddress.line1 +
                    item.shippingAddress.line2 +
                    ', ' +
                    item.shippingAddress.city +
                    ', ' +
                    item.shippingAddress.state
                  }}
                </q-tooltip>
              </li>
              <li>
                <span>Order Total:</span>
                <font :class="{ 'text-strike': orderType == 'CO' }">
                  {{ item.totalOrderAmount | currency }}
                </font>
              </li>
            </ul>
            <!-- -->
            <q-list
              v-if="item.trackOrderLineItems.length === 1"
              :class="{ singleItem: true, disable: orderType == 'CO' }"
            >
              <q-item>
                <q-item-section avatar>
                  <q-avatar square>
                    <router-link
                      :to="{
                        name: orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                        params: {
                          orderId: item.trackOrderLineItems[0].orderId,
                        },
                      }"
                    >
                      <img
                        :src="
                          item.trackOrderLineItems[0].image
                            | defaultProductImage
                        "
                      />
                    </router-link>
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: {
                          seoName: item.trackOrderLineItems[0].seoName,
                        },
                      }"
                    >
                      {{ item.trackOrderLineItems[0].productName }}
                    </router-link>
                  </q-item-label>
                  <q-item-label caption lines="2">
                    {{ item.trackOrderLineItems[0].qty }} x
                    <strong class="q-mr-sm">
                      {{ item.trackOrderLineItems[0].price | currency }}
                    </strong>
                    <!-- <span class="text-strike">
                      {{ item.trackOrderLineItems[0].finalPrice | currency }}
                    </span> -->
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
            <q-list v-else>
              <q-item
                v-for="(prodct, index) in item.trackOrderLineItems.slice(0, 3)"
                :key="index"
                :class="{ disable: orderType == 'CO' }"
              >
                <template>
                  <q-item-section avatar>
                    <q-avatar square>
                      <router-link
                        :to="{
                          name:
                            orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                          params: { orderId: prodct.orderId },
                        }"
                      >
                        <img :src="prodct.image | defaultProductImage" />
                      </router-link>
                    </q-avatar>
                  </q-item-section>
                </template>
              </q-item>
              <q-item v-if="item.trackOrderLineItems.length > 3" class="last">
                <q-item-section avatar>
                  <q-avatar square>
                    <router-link
                      :to="{
                        name: orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                        params: { orderId: item.orderId },
                      }"
                    >
                      <span>
                        <strong>
                          +{{ item.trackOrderLineItems.length - 3 }}
                        </strong>
                        More
                      </span>
                    </router-link>
                  </q-avatar>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-card-actions class="q-pa-none">
            <!-- <q-btn
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              label="Reorder"
              align="left"
              size="13px"
              class="q-px-sm full-width"
              v-if="orderType == 'OH'"
              @click="applyReorder(item)"
            /> -->

            <q-btn
              :to="{
                name: 'TrackOrder',
                params: {
                  orderId: item.orderId,
                },
              }"
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              color="primary"
              label="Track your order"
              align="left"
              size="13px"
              class="q-px-sm full-width"
              v-if="orderType == 'TO'"
            />
            <q-btn
              :to="{
                name: 'OrderStartReturn',
                params: { orderId: item.orderId },
              }"
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              color="primary"
              label="Start A Return"
              size="13px"
              class="q-px-sm full-width"
              v-if="orderType === 'OH' && item.isReturnPossible && false"
            />
            <q-btn
              :to="{ name: 'OrderDetail', params: { orderId: item.orderId } }"
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              color="tertiary"
              label="View Details"
              size="13px"
              class="q-px-sm full-width"
              v-if="orderType !== 'TO'"
            />
            <q-btn
              v-if="orderType !== 'CO'"
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              color="tertiary"
              text-color="black"
              label="Invoice"
              size="13px"
              class="q-px-sm full-width"
              @click="downloadOrderInvoice(item)"
            />
            <q-btn
              v-if="
                (orderType == 'TO' && item.allowModification) ||
                (orderType == 'TO' && item.allowCancelOrder)
              "
              @click="cancelOrder(item.orderId)"
              unelevated
              flat
              icon-right="keyboard_arrow_right"
              text-color="colorful"
              label="Cancel Order"
              size="13px"
              class="q-px-sm full-width"
              :loading="cancelingdOrder[item.orderId]"
            />
          </q-card-actions>
        </q-card>
      </q-expansion-item>
      <div class="orderItemTop" v-if="!isMobile">
        <div class="row items-center">
          <div class="col-12 col-md-8">
            <ul class="orderItemInfo">
              <li class="oItemIcon" style="flex: 0 0 40px">
                <SvgIcon
                  color="primaryOnBody"
                  size="1.5rem"
                  :icon="item.codeName"
                />
              </li>
              <li class="oItemStore" v-if="orderType == 'OH'">
                <span>Store:</span>
                {{ item.storeName }}
              </li>
              <li class="oItemDate">
                <span>Order Date:</span>
                {{ item.orderDate | formatDate }}
              </li>
              <li class="oItemNo"
                ><span>Order Number:</span> {{ item.orderNumber }}</li
              >
              <li class="oItemAddress" v-if="item.pickupStore">
                <span>Pickup From:</span>
                {{ item.pickupStore.name }}
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  content-class="tooltip-150"
                >
                  {{
                    item.pickupStore.line1 +
                    (item.pickupStore.line2
                      ? ', ' + item.pickupStore.line2
                      : '') +
                    ', ' +
                    item.pickupStore.city +
                    ', ' +
                    item.pickupStore.state +
                    ', ' +
                    item.pickupStore.zip
                  }}
                </q-tooltip>
              </li>
              <li class="oItemAddress" v-if="item.shippingAddress">
                <span>
                  {{ item.codeName == 'scd' ? 'Deliver To:' : 'Ship To:' }}
                </span>
                {{
                  (
                    (item.shippingAddress.firstName || '') +
                    ' ' +
                    (item.shippingAddress.lsatName || '')
                  ).trim()
                }}
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  content-class="tooltip-150"
                >
                  {{
                    item.shippingAddress.line1 +
                    item.shippingAddress.line2 +
                    ', ' +
                    item.shippingAddress.city +
                    ', ' +
                    item.shippingAddress.state
                  }}
                </q-tooltip>
              </li>
              <li class="oItemTotal">
                <span>Order Total:</span>
                <font :class="{ 'text-strike': orderType == 'CO' }">
                  {{ item.totalOrderAmount | currency }}
                </font>
              </li>
              <li class="oItemStore" v-if="orderType == 'TO'">
                <span>Order Status:</span>
                {{ getOrderStatus(item.orderTrackings) }}
              </li>
            </ul>
          </div>

          <div class="col-12 col-md-4 actions">
            <div v-if="orderType == 'CO'" class="btn-cancel q-mr-sm">
              Canceled
            </div>
            <span
              v-if="orderType == 'OH' && item.hasAnyReturn"
              class="btn-returned q-mr-sm"
            >
              <SvgIcon icon="return" />Return
              <!-- Initiated -->
            </span>
            <span v-if="orderType == 'OH'" class="btn-completed q-mr-sm">
              <SvgIcon icon="checkmark" />Completed
            </span>
            <q-btn
              unelevated
              flat
              no-wrap
              @click="cancelOrder(item.orderId)"
              class="btn-cancel q-mr-sm"
              v-else-if="
                (orderType == 'TO' && item.allowModification) ||
                (orderType == 'TO' && item.allowCancelOrder)
              "
              :loading="cancelingdOrder[item.orderId]"
            >
              <SvgIcon icon="close" />Cancel Order
            </q-btn>
          </div>
        </div>
      </div>
      <div class="orderItemMiddle" v-if="!isMobile">
        <div class="row items-center">
          <div class="col-12 col-md-6">
            <q-list
              v-if="item.trackOrderLineItems.length === 1"
              :class="{ singleItem: true, disable: orderType == 'CO' }"
            >
              <q-item>
                <q-item-section avatar>
                  <q-avatar square>
                    <router-link
                      :to="{
                        name: orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                        params: { orderId: item.orderId },
                      }"
                      class="bg-white"
                    >
                      <q-img
                        :src="
                          item.trackOrderLineItems[0].image
                            | defaultProductImage
                        "
                        contain
                        style="width: 40px; height: 40px"
                      />
                    </router-link>
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    <router-link
                      :to="{
                        name: 'Detail',
                        params: {
                          seoName: item.trackOrderLineItems[0].seoName,
                        },
                      }"
                    >
                      {{ item.trackOrderLineItems[0].productName }}
                    </router-link>
                  </q-item-label>
                  <q-item-label caption lines="2">
                    {{ item.trackOrderLineItems[0].qty }} x
                    <strong class="q-mr-sm">
                      {{ item.trackOrderLineItems[0].price | currency }}
                    </strong>
                    <!-- <span class="text-strike">
                      {{ item.trackOrderLineItems[0].finalPrice | currency }}
                    </span> -->
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
            <q-list v-else>
              <q-item
                v-for="(prodct, index) in item.trackOrderLineItems.slice(0, 3)"
                :key="index"
                :class="{ disable: orderType == 'CO' }"
              >
                <template>
                  <q-item-section avatar>
                    <q-avatar square>
                      <router-link
                        :to="{
                          name:
                            orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                          params: { orderId: item.orderId },
                        }"
                        class="bg-white"
                      >
                        <q-img
                          :src="prodct.image | defaultProductImage"
                          contain
                          style="width: 40px; height: 40px"
                        />
                      </router-link>
                    </q-avatar>
                  </q-item-section>
                </template>
              </q-item>
              <q-item v-if="item.trackOrderLineItems.length > 3" class="last">
                <q-item-section avatar>
                  <q-avatar square>
                    <router-link
                      :to="{
                        name: orderType == 'TO' ? 'TrackOrder' : 'OrderDetail',
                        params: { orderId: item.orderId },
                      }"
                    >
                      <span>
                        <strong>
                          +{{ item.trackOrderLineItems.length - 3 }}
                        </strong>
                        More
                      </span>
                    </router-link>
                  </q-avatar>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-12 col-md-6 actions">
            <!-- <q-btn
              unelevated
              color="primary"
              no-caps
              class="q-px-sm q-ml-sm q-mr-sm"
              size="12px"
              rounded
              v-if="orderType === 'OH'"
              @click="applyReorder(item)"
            >
              <SvgIcon
                size="1rem"
                :icon="item.codeName"
                class="q-mr-sm"
              />Reorder
            </q-btn> -->

            <q-btn
              :to="{
                name: 'TrackOrder',
                params: {
                  orderId: item.orderId,
                },
              }"
              unelevated
              :rounded="!isMobile"
              color="secondaryOnBody"
              label="View Details"
              size="12px"
              class="q-px-sm q-ml-sm q-mr-sm"
              v-if="orderType == 'TO'"
            />
            <q-btn
              :to="{
                name: 'OrderStartReturn',
                params: { orderId: item.orderId },
              }"
              unelevated
              :rounded="!isMobile"
              color="primary"
              label="Start A Return"
              size="12px"
              class="q-px-sm q-ml-sm q-mr-sm"
              v-if="orderType === 'OH' && item.isReturnPossible && false"
            />
            <q-btn
              :to="{ name: 'OrderDetail', params: { orderId: item.orderId } }"
              unelevated
              :rounded="!isMobile"
              color="secondaryOnBody"
              label="View Details"
              size="12px"
              class="q-px-sm q-ml-sm q-mr-sm"
              v-if="orderType !== 'TO'"
            />
            <q-btn
              v-if="!isMobile && orderType !== 'CO'"
              unelevated
              :rounded="!isMobile"
              color="white"
              text-color="black"
              label="Invoice"
              size="12px"
              class="q-px-sm q-ml-sm q-mr-sm"
              @click="downloadOrderInvoice(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <RorderConfirmDialog /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { hideShowFullSpinner, saveBase64AsPDF } from 'src/utils'
// import RorderConfirmDialog from 'components/common/ReorderConfirmDialog.vue'
export default {
  props: {
    orderType: {
      type: String,
      default: '',
    },
    orders: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  name: 'CustomerOrders',
  data() {
    return {
      cancelingdOrder: {},
    }
  },
  // components: {
  //   RorderConfirmDialog,
  // },
  computed: {
    ...mapGetters('order', ['trackOrderDetailById', 'orderInfoById']),
  },
  methods: {
    ...mapActions('order', ['generateInvoice']),
    cancelOrder(orderId) {
      this.ftxConfirm('Are you sure you want to cancel your order?')
        .onOk(() => {
          this.$set(this.cancelingdOrder, orderId, true)

          let reqData = {
            orderId,
          }
          this.$store
            .dispatch('order/deleteOrder', reqData)
            .then((response) => {
              if (response && response.success) {
                this.$emit('order-canceled', orderId)
              } else if (response.errors && response.errors.length) {
                this.showError(response.errors[0].descreption)
              }
            })
            .finally(() => {
              this.$set(this.cancelingdOrder, orderId, false)
            })
        })
        .onCancel(() => {})
        .onDismiss(() => {})
    },
    async downloadOrderInvoice(orderItem) {
      hideShowFullSpinner(true)
      try {
        let orderObj = {}
        if (this.orderType == 'TO') {
          if (!this.$store.state.order.trackOrderDetail[orderItem.orderId]) {
            await this.$store
              .dispatch('order/trackOrder', {
                orderId: orderItem.orderId,
                showLoader: true,
                invoiceError: true,
              })
              .then((response) => {
                if (response) orderObj = response
              })
          } else {
            orderObj = this.trackOrderDetailById(orderItem.orderId)
          }
        } else {
          if (!this.$store.state.order.detail[orderItem.orderId]) {
            await this.$store
              .dispatch('order/viewOrderDetail', orderItem.orderId)
              .then((response) => {
                if (response) orderObj = response
              })
          } else {
            orderObj = this.orderInfoById(orderItem.orderId)
          }
        }

        if (this.isCordova) {
          this.downloadFile(orderItem)
        } else {
          this.generateInvoice({
            orderId: orderItem.orderId,
            showLoader: true,
          })
            .then((response) => {
              hideShowFullSpinner(false)
              if (response?.data && response.data instanceof Blob) {
                const file = new Blob([response.data], {
                  type: 'application/pdf',
                })
                const fileURL = window.URL.createObjectURL(file)
                const link = document.createElement('a')
                link.href = fileURL
                link.download = 'Order-' + orderItem.orderNumber + '.pdf'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            })
            .catch((error) => {
              console.log(error)
              this.showError(
                '<strong>Error!</strong> Sorry! PDF is not available.'
              )
            })
        }
      } catch (err) {
        hideShowFullSpinner(false)
      }
    },
    // async applyReorder(item) {
    //   let params = {
    //     orderId: item.orderId,
    //     body: {
    //       fulfillmentMethodId: item.fulfillmentMethodID,
    //       locationId: this.currentLocationId,
    //     },
    //   }
    //   hideShowFullSpinner(true)
    //   await this.$store
    //     .dispatch('order/applyReorder', params)
    //     .then((response) => {
    //       if (
    //         response &&
    //         response.success &&
    //         response.data &&
    //         response.data.notAvailableProducts &&
    //         !response.data.notAvailableProducts.length
    //       ) {
    //         this.changeDialogState({
    //           dialog: 'RorderConfirmDialog',
    //           val: false,
    //         })
    //         this.notify('Item Updated to Cart.')
    //       } else if (
    //         response &&
    //         response.success &&
    //         response.data &&
    //         response.data.notAvailableProducts &&
    //         response.data.notAvailableProducts.length
    //       ) {
    //         this.changeDialogState({
    //           dialog: 'RorderConfirmDialog',
    //           val: false,
    //         })
    //         setTimeout(() => {
    //           this.changeDialogState({
    //             dialog: 'RorderConfirmDialog',
    //             val: true,
    //             properties: {
    //               orderId: item.orderId,
    //               selectedFulfillment: item.fulfillmentMethodID,
    //               notAvailableProductsCount:
    //                 response.data.notAvailableProducts.length,
    //             },
    //           })
    //           if (response.data.shoppingCartDetailViewModel)
    //             this.notify('Item Updated to Cart.')
    //         }, 500)
    //       } else if (
    //         response &&
    //         response.errors &&
    //         response.errors.length &&
    //         response.errors[0].errorCode == 'NOT_FOUND'
    //       ) {
    //         this.changeDialogState({
    //           dialog: 'RorderConfirmDialog',
    //           val: true,
    //           properties: {
    //             orderId: item.orderId,
    //             notAvailableProductsCount: 0,
    //             selectedFulfillment: item.fulfillmentMethodID,
    //             updateFulfillmentAndReorder: this.applyReorder,
    //           },
    //         })
    //       } else if (
    //         response &&
    //         response.errors &&
    //         response.errors.length &&
    //         response.errors[0].errorCode == 'NOT_FOUND_CARTITEMS'
    //       ) {
    //         this.showError("You can't reorder fully returnable order.")
    //       } else if (response && response.errors && response.errors.length > 0)
    //         this.showError(response.errors[0].descreption)
    //     })
    //     .finally(() => hideShowFullSpinner(false))
    // },
    notify(message) {
      this.showSuccess(
        message,
        this.$route.name != 'Cart'
          ? [
              {
                color: 'dark',
                label: 'Go To Cart',
                color: 'orange-5',
                handler: () => {
                  if (this.isMobile)
                    this.$router
                      .push({
                        name: 'Cart',
                      })
                      .catch(() => {})
                  else
                    this.changeDialogState({
                      dialog: 'cartDrawer',
                      val: true,
                    })
                },
              },
            ]
          : []
      )
    },
    getOrderStatus(orderTrackings) {
      let statusObjs

      if (orderTrackings)
        statusObjs = orderTrackings.filter((item) => {
          return item.isReached
        })

      return statusObjs && statusObjs.length
        ? statusObjs[statusObjs.length - 1].title
        : 'N/A'
    },
    downloadFile(orderItem) {
      hideShowFullSpinner(true)
      let endpoint =
          process.env.API_ROOT_URI +
          `/v1/Order/PrintInvoice?orderId=${orderItem.orderId}`,
        fileName = 'Order-' + orderItem.orderNumber + '.pdf',
        contentType = 'application/pdf',
        destinationPath =
          cordova.file.externalRootDirectory + `Download/${fileName}`,
        accessToken = this.$store.getters['auth/accessToken'],
        tenantId = process.env.Tenant

      var request = {
        uri: endpoint,
        title: fileName,
        description: 'Downloading...',
        mimeType: contentType,

        visibleInDownloadsUi: true,
        notificationVisibility: 1,
        destinationUri: destinationPath,
        headers: [
          {
            header: 'Authorization',
            value: `Bearer ${accessToken}`,
          },
          {
            header: 'Tenant',
            value: tenantId,
          },
        ],
      }

      cordova.plugins.Downloader.download(
        request,
        (location) => {
          console.log('File is downloaded at ' + location)
          hideShowFullSpinner(false)
          this.showSuccess('Invoice saved successfully!.')
        },
        (err) => {
          console.log('error', err)
          hideShowFullSpinner(false)
          this.showError('<strong>Error!</strong> Sorry! PDF is not available.')
        }
      )
    },
  },
}
</script>
